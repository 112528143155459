<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">健康計步</span>
      <div class="flexH width right"></div>
    </section>

    <div class="banner flex justify-center items-center">
      <div class="flexV justify-center items-center w-full">
        <div class="text-white mb-1">今日步數</div>
        <div class="text-white" style="font-size: 2.75rem">{{ todaySteps[0]?.step_count }}</div>
        <div class="records flexV width padding">
          <div class="margin-auto">
            <div class="flexH margin relative card">
              <div class="flexV width gap-1">
                <span class="fS font-normal font-lg fz-100 text-white">
                  每日達成 5000 步即達標
                </span>
                <span class="fS font-normal cNeutra500 fL text-white"
                  ></span
                >
              </div>
              <div class="tag cNeutral500" style="background: #ffc403;" v-if="isComplete(todaySteps[0])">已達標</div>
              <div class="tag cNeutral500" v-else>尚未達標</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div>
          <div class="record flexH width margin relative">
            <div class="flexV width gap-1">
              <span class="font-normal cNeutral900 font-lg fz-100">
                達標總次數 PR 值：
                <span class="fL primary">{{history.pr}}</span>
              </span>
              <span class="fS font-normal cNeutra500 fL text-gray-500"
                >您在所有用戶當中，達標次數贏過 {{history.pr}}% 的人</span
              >
            </div>
          </div>

          <div class="flex text-center">
            <div class="record flexH width margin relative">
              <div class="flexV width gap-1">
                <span class="font-normal cNeutral900 font-lg fz-100">
                  達標總次數：
                  <span class="fL primary">{{totalCompleteCount}}</span>
                </span>
              </div>
            </div>
            <div class="record flexH width margin relative">
              <div class="flexV width gap-1">
                <span class="font-normal cNeutral900 font-lg fz-100">
                  總步數：
                  <span class="fL primary">{{history.total_steps}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">歷史紀錄</div>

      <table class="table">
        <thead>
          <th>日期</th>
          <th>當日計數</th>
          <th>是否達標</th>
        </thead>
        <tbody class="text-center">
          <tr v-for="(item, index) in history.list" :key="index">
            <td>{{ item.step_date }}</td>
            <td>{{ item.step_count }}</td>
            <td>{{ getCompleteText(item) }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      latestReadAt: 0,
      status: 0,
      history: {},
      data: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  created() {
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  beforeDestroy() {
    document.removeEventListener("deviceready", this.onDeviceReady, false);
  },
  mounted() {
    const payload = {
      type: 'upload_steps'
    }
    window.Yunlin?.postMessage(JSON.stringify(payload))
    window.flutter_inappwebview?.callHandler('Yunlin', JSON.stringify(payload));
    console.log("JS Interface", payload)
    this.getRecords()
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    todaySteps() {
      const d = new Date()
      const today = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`
      return this.history?.list?.filter(item => item.step_date === today ) || []
    },
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
    totalCompleteCount() {
      return this.history?.list?.filter(item => this.isComplete(item)).length || 0
    }
  },
  methods: {
    getRecords() {
      this.$http({
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/user-steps/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}`,
        method: 'GET'
      }).then(res => {
        return res.data
      }).then(r => {
        this.history = r
      })
    },
    goBack() {
      this.$router.push('/service')
    },
    onDeviceReady() {
      const healthKit = window.plugins?.healthkit || cordova.plugins?.health;
      if (healthKit) {
        // console.log("Health plugin loaded");
        // console.log(window.plugins.healthkit)
        // console.log(window.plugins.healthkit.available)
        // console.log(window.plugins.healthkit.checkAuthStatus)
        // console.log(window.plugins.healthkit.requestAuthorization)
        const healthAvalable = healthKit.available || healthKit.isAvailable;
        if (typeof healthAvalable === "function") {
          healthAvalable(
            (available) => {
              console.log("Health plugin is available: ", available);
              if (available) {
                let supportedTypes = [
                  "HKQuantityTypeIdentifierHeight",
                  "HKQuantityTypeIdentifierStepCount",
                  "HKQuantityTypeIdentifierDistanceWalkingRunning",
                  "HKCategoryTypeIdentifierSleepAnalysis",
                  "HKQuantityTypeIdentifierDietaryEnergyConsumed",
                  "HKQuantityTypeIdentifierDietaryFatTotal",
                ];
                // auth
                healthKit.requestAuthorization(
                  {
                    ...(cordova
                      ? { read: ["steps"] }
                      : {
                          readTypes: supportedTypes,
                          writeTypes: supportedTypes,
                        }),
                  },
                  () => {
                    console.log("Authorization successful!");
                    this.getHealthData();
                  },
                  (err) => {
                    console.error("Authorization failed:", err);
                  }
                );
              } else {
                console.error("Health plugin is not available");
              }
            },
            function (err) {
              console.error("Error checking availability:", err);
            }
          );
        } else {
          console.error(
            "window.plugins.healthkit.isAvailable is not a function"
          );
        }
      }
    },
    isComplete(item) {
      return item?.step_count >= 5000;
    },
    getCompleteText(item) {
      return this.isComplete(item) ? "達標" : "未達標";
    },
    getHealthData() {
      const healthKit = window.plugins?.healthkit || cordova.plugins?.health;
      if (!healthKit) {
        return;
      }
      const self = this;
      const query = healthKit.querySampleType || healthKit.queryAggregated;
      query(
        {
          startDate: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000), // three days ago
          endDate: new Date(), // now
          // 'sampleType': 'HKQuantityTypeIdentifierBasalBodyTemperature',
          sampleType: "HKQuantityTypeIdentifierStepCount", // anything in HealthKit/HKTypeIdentifiers.h
          dataType: "steps",
          bucket: "day",
          // 'unit': 'degF' // make sure this is compatible with the sampleType
        },
        function (data) {
          console.log("Steps data:", data);
          self.data = data;
          self.items = data.map((item) => {
            const date = new Date(item.startDate);
            return {
              date: `${date.getFullYear()}/${
                date.getMonth() + 1
              }/${date.getDate()}`,
              count: item.value,
              status: "1",
            };
          });
        },
        function (err) {
          console.log("Error querying health data:", err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

.banner {
  margin-top: 60px;
  height: 216px;
  background: radial-gradient(circle, #601bf6, #d1c4e9);
  border-radius: 0 0 5% 5%;
}

.notice.main {
  padding: 0 !important;
}

.card {
  background-color: rgba(151, 110, 110, 0.2);
  padding: 12px;
  margin: 0 auto;
  width: 343px;
}

.tag {
  background-color: #fff;
  border-radius: 25px;
  padding: 5px;
  flex: 0 0 auto;
  text-align: center;
  font-size: 0.75rem;
}

.table {
  border-collapse: collapse; /* 合并边框 */
  border-radius: 20px;
  overflow: hidden; /* 确保圆角效果 */
  thead {
    background-color: var(--c003);
    th {
      padding: 10px 0;
    }
  }
  td {
    padding: 10px 0;
  }
  tr {
    border-bottom: 1px solid #ddd !important;
  }

  tr:last-child {
    border-bottom: none;
  }
}
</style>
