import store from "@/store";
import i18n from "@/i18n";

function updateLanguage(setting, lang) {
  if (lang) {
    let language = lang == "en" ? lang : "zh-TW";
    localStorage.setItem("lang", language);
    setting.locale = language;
  } else {
    setting.locale = localStorage.getItem("lang");
  }
}

function getUserInfo(userId) {
  let apiHost = process.env.VUE_APP_API_HOST;
  let merchantId = process.env.VUE_APP_MERCHANT_ID;
  let config = {
    method: "get",
    url: `${apiHost}/identities/v1/merchants/${merchantId}/members/${userId}`,
    headers: {},
  };

  return qcsso
    .request(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

const guestPaths = [
  "/before-login",
]

function auth(router) {
  router.beforeEach((to, from, next) => {
    console.log("beforeEach: ", to, from);
    // console.log('to.meta:', to.meta)
    store.commit("updateCurrentPath", to.path);
    updateLanguage(i18n, to.query.lang);
    // if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.


    // if (guestPaths.includes(to.path)) {
    //   return next()
    // }

    qcsso
      .init({
        appId: process.env.VUE_APP_ID, // tycard staging man
        params: { referralCode: to.query.referralCode || "" },
      })
      .then(() => {
        if (guestPaths.includes(to.path)) {
          return next()
        }

        if (!qcsso.isLoggedIn()) {
          console.log('to before-login');
          next({
            path: '/before-login'
          });

          // qcsso.login({
          //   redirectUrl: `${process.env.VUE_APP_HOST}${process.env.BASE_URL}${
          //     to.query.redirectPath || ""
          //   }`,
          // });
          // qcsso.login({
          //     redirectUrl: `${process.env.VUE_APP_HOST}${process.env.BASE_URL}${to.path.slice(1)}`
          // });
          // 未登入者，重導致訪客頁
          // next({
          //     path: '/guest',
          //     query: {...to.query, redirectPath: to.path.substring(1)}
          // })
        } else {
          const { user } = store.state;
          if (!user.uuid) {
            return qcsso
              .getProfile()
              .then(function (user) {
                store.commit("updateUser", user);
  
                if (Object.keys(store.state.userInfo).length !== 0)
                  return to.path == "/" ? next("/home") : next();
  
                getUserInfo(user.userId).then((res) => {
                  console.log("userInfo ", res);
                  store.commit("updateUserInfo", res);
                  if (sessionStorage.getItem('hasSetLogin') !== 'true') {
                    sessionStorage.setItem('hasSetLogin', true)
                    const args = {
                      type: "login",
                      payload: {
                        token: localStorage.getItem(`QCSSO:${sessionStorage.getItem('QCSSO:sessionAppId')}:accessToken`),
                        user_id: user.userId,
                        uuid: user.userId,
                        member_id: res.memberId
                      }
                    }
                    window.Yunlin?.postMessage(JSON.stringify(args))
                    window.flutter_inappwebview?.callHandler('Yunlin', JSON.stringify(args));
                    console.log("JS Interface", {
                      type: "login",
                      payload: {
                        token: localStorage.getItem(`QCSSO:${sessionStorage.getItem('QCSSO:sessionAppId')}:accessToken`),
                        user_id: user.userId,
                        uuid: user.userId,
                        member_id: res.memberId
                      }
                    })
    
                  }
                  if (to.path == "/") next("/home");
  
                  next();
                });
              })
              .catch(function (e) {
                console.log(e);
                next("/error");
              });
          }

          if (to.path == "/") return next("/home");
          next();
        }
      });
    // } else if (to.matched.some(record => record.meta.pwaCheck)) { // 不用登入但是如果登入就跳轉
    //     qcsso
    //     .init({
    //         appId: process.env.VUE_APP_ID, // tycard staging man
    //         params: { referralCode: to.query.referralCode || "" }
    //     })
    //     .then(() => {
    //         if (!qcsso.isLoggedIn()) {
    //             next();
    //         } else {
    //             next('/home')
    //         }
    //     });

    // } else {
    //     console.log("error")
    //     next() // make sure to always call next()!
    // }
  });
}

export default auth;
