<template>
  <div id="app">
    <div class="body fixed mobile">
      <section class="header store_register">
        <div class="flexH width">
          <a
            class="icon iconButton third"
            @click.prevent="$router.push('/profile')"
          >
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
          </a>
        </div>
        <span class="fL nowrap">{{ $t("setting") }}</span>
        <div class="flexH width right">
          <!-- <a class="textButton fM third">草稿暫存</a> -->
        </div>
      </section>
      <div class="main bg-white">
        <ul>
          <li class="flex justify-between text-lg pt-12p pb-12p">
            <a
              href="/notification-setting"
              class="flex justify-between flex-1 items-center"
            >
              <span> 通知設定 </span>
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
                color="#6b2df5"
              />
            </a>
          </li>
          <li class="flex justify-between text-lg pt-12p pb-12p">
            <div class="flex justify-between flex-1 items-center" @click="updateApp">
              <span> 檢查更新 </span>
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
                color="#6b2df5"
              />
            </div>
          </li>
          <li class="flex justify-between text-lg pt-12p pb-12p">
            <a class="w-full flex justify-between" @click.prevent="$router.push('/static?type=terms')">
  
              <span> 服務條款與隱私條款 </span>
              <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
            </a>
          </li>
           <li class="flex justify-between text-lg pt-12p pb-12p" @click="clickRemoveAccount">
            <span> 刪除雲林幣扭一下帳號 </span>
            <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
          </li>
          <li class="flex justify-between text-lg pt-12p pb-12p">
            <div class="w-full flex justify-between" @click="() => linkClickHandler('https://bit.ly/4dMk6Xp')">
              <span> 關於雲林幣扭一下2.0 </span>
              <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
            </div>
          </li>
         
          <li class="flex justify-between text-lg pt-12p pb-12p" @click="logout()">
            <span > 登出 </span>
            <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
          </li>
        </ul>
      </div>
      <section v-if="showConfirm" class="s-popup">
        <div class="popBody">
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div>
              {{ $t("alert") }}
            </div>
            <span class="text-center mt-2">{{ $t("clean-cache-desc") }}</span>
          </section>
          <section class="popFooter">
            <button
              class="button rounded white cancel"
              @click="showConfirm = false"
            >
              {{ $t("cancel") }}
            </button>
            <a class="button submit rounded">
              <span class="fM">{{ $t("confirm") }}</span>
            </a>
          </section>
        </div>
      </section>
      <AppUpdateDialog v-if="showDialog" @close="() => showDialog=false" />
      <section id="popLeave" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="closeLeaveModal"
            ><font-awesome-icon
                icon="fas fa-times"
            ></font-awesome-icon
          ></a>
          <section class="popHeader">
              <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="fL text-center">提示</div>
            <div class="icon">
            </div>
            <span class="">您即將離開 App 前往外部連結，請問是否繼續？</span>
          </section>
          <section class="popFooter flex">
            <a
              class="button bg-transparent text-black border border-primary flex-1  submit rounded"
              @click.prevent="closeLeaveModal"
              >返回</a
            >
            <a
              class="button flex-1 submit rounded"
              @click.prevent="goOut()"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>
    </div>
    <ConfirmDialog
      ref="removeAccountDialogRef"
      :type="removeAccountDialogInfo.type"
      :title="removeAccountDialogInfo.title"
      :content="removeAccountDialogInfo.content"
      :buttons="removeAccountDialogInfo.buttons"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import AppUpdateDialog from "@/components/Dialog/AppUpdateDialog.vue";
import ConfirmDialog from "@/components/Dialog/ConfirmDialog.vue";

export default {
  name: "Settings",
  components: {
    AppUpdateDialog,
    ConfirmDialog
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      showConfirm: false,
      showDialog: false,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
      link: '',
    };
  },
  mounted() {
    if (sessionStorage.getItem('reloadAlert') === 'true') {
      // 顯示 alert
      this.showDialog = true;
      
      // 移除標記，防止後續的頁面重新加載還觸發 alert
      sessionStorage.removeItem('reloadAlert');
    }
  },
  computed: {
    ...mapState(["user"]),
    removeAccountDialogInfo() {
      return {
        type: "info",
        title: "提醒",
        content: "一旦您點選確認刪除帳號，您將無法再次登入帳號且無法恢復帳號內的資料。帳號經刪除後，同手機號碼30天內無法重新註冊也無法復原您的帳號，確定要刪除嗎？",
        buttons: [
          {
            text: "取消",
            outline: true,
            click: ({ close }) => {
              close();
            },
          },
          {
            text: "確定",
            click: ({ close }) => {
              close();
              this.removeAccount()
            },
          },
        ],
      }
    }
  },
  methods: {
    logout() {
      qcsso
        .logout({
          logoutRedirectUri: this.appHost.substring(0, this.appHost.length - 1),
        })
        .then(() => {
          window.Yunlin?.postMessage(JSON.stringify({ type: "logout" }))
          window.flutter_inappwebview?.callHandler('Yunlin', JSON.stringify({ type: "logout" }));
        })
        .catch((err) => console.log(err)).finally(() => {
          sessionStorage.removeItem('hasSetLogin')
          console.log("JS Interface", { type: "logout" })
        })
    },
    clickRemoveAccount() {
      this.$refs.removeAccountDialogRef.open();
    },
    async removeAccount() {
      try {
        await this.removeAccountApi()
        this.logout()
      } catch (error) {
        console.log('error', error);
      }
    },
    async removeAccountApi() {
      await this.$http({
        url: `${this.apiHost}/identities/v1/merchants/yunlin/members/${this.user.userId}`,
        method: "DELETE",
      });
    },
    updateApp() {
      sessionStorage.setItem('reloadAlert', 'true');
      window.location.reload();
    },
    linkClickHandler(url) {
      this.openLeaveModal();
      this.link = url
    },
    goOut() {
      this.closeLeaveModal()
      window.open(this.link, '_blank');
    },
    openLeaveModal() {
      const wrap = document.querySelector('#popLeave')
      wrap.style.display = 'flex'
    },
    closeLeaveModal() {
      const wrap = document.querySelector('#popLeave')
      wrap.style.display = 'none'
    },
  }
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     console.log("user: ", that.user);
  //     if (that.user && that.user.userId) {
  //       vm.getUserPoints()
  //         .then((res) => {
  //           that.$store.commit("updateUserPoints", res);
  //         })
  //         .catch((err) => {
  //           console.log("err: ", err);
  //           next("/error");
  //         });
  //       vm.$store.commit(
  //         "updateUserInfo",
  //         Object.assign({}, vm.userInfo, {
  //           myReferralCode: vm.user.user.myReferralCode,
  //         })
  //       );
  //       vm.myReferralCode = vm.user.user.myReferralCode;
  //     } else {
  //       next("/error");
  //     }
  //   });
  // },
};
</script>
<style scoped>
ul {
  padding: 0;
}

li {
  list-style: none;
  border-bottom: 1px solid #d3d3d3;
  padding-right: 8px;
}

.s-popup {
  display: flex !important;
}

.cancel {
  border: 1px solid #6b2df5;
}
</style>
