<template>
    <div class="body fixed mobile mini-game-page">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <Spinner :spin="!isCampaignsLoaded"/>
        <!-- Header -->
        <section class="header activity">
            <div class="flexH width">
                <a
                    class="icon iconButton third"
                    @click.prevent="$router.push('/home')"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">小遊戲</span>
            <!-- Right -->
            <div class="flexH width right">
                <!-- <a class="textButton fM third" @click.prevent="$router.push('/campaign-records')">獲獎紀錄</a> -->
            </div>
        </section>
        <!-- Main -->
        <section class="main activity">
            <div v-if="campaigns.length > 0" class="cards">
                <!-- 最新任務 -->
                <a
                    class="news flexV news-card"
                    v-for="(campaign, index) in campaigns"
                    :key="'campaign' + index"
                    @click.prevent="toCampaignDetail(campaign)"
                >
                    <div class="uide-mission-card">
                        <div class="uide-mission-card-top">
                            <div :class="campaign.departmentName && 'uide-mission-card-top-chip'">{{ campaign.departmentName}}</div>
                            <div>{{ tsToDateDay(campaign.campaignStartTime) }}</div>
                        </div>
                        <div class="uide-mission-card-title">
                            {{ campaign.campaignName}}
                        </div>
                        <div class="ellipsis-two-line">
                            {{ parseContent(campaign.campaignDesc) }}
                        </div>
                        <!-- <div class="flex gap-1 items-center">
                            <img  width="20" height="20" src="@/assets/icon/uide/coin.svg" />
                            <span>雲林幣{{ campaign.reward_point }}枚</span>
                        </div> -->
                        <div class="flex gap-1 items-center justify-between ">
                            <!-- <ProgressBar  style="width:228px;" :progress="((campaign.reward_total - campaign.reward_remain) / campaign.reward_total) * 100"/> -->
                             <div></div>
                            <div v-if="showStatusBtn(campaign)" :class="[ 'uide-mission-card-progress-btn', 'status' + getStatusId(campaign) ]">{{ getStatusName(campaign) }}</div>
                        </div>
                        <!-- <div class="flex gap-1 items-center ">
                            <img  width="16" height="16" src="@/assets/icon/uide/people.svg" />
                            <span class="uide-mission-card-user">獎勵剩餘人數 {{ campaign.reward_remain }} 名 / 總人數 {{ campaign.reward_total }} 名</span>
                        </div> -->
                        <img src="@/assets/icon/bell-v2.svg"
                            :class="['uide-mission-card-bell', { hide: campaign.unread === false }]"
                        />
                    </div>
                </a>
            </div>
            <div v-else>
                <!-- 無內容 -->
                <div class="listEmpty flexV width center">
                    <img src="@/assets/icon/empty-doc.svg" />
                    <span class="fL gray mt-4">很抱歉，目前尚未有任務</span>
                </div>
            </div>
        </section>
        <AlertDialog
            v-if="showAlert"
            :title="alert.title"
            :content="alert.message"
            @close="onCloseAlertDialog"
        />
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';
// import ProgressBar from '@/components/ProgressBar.vue';
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

export default {
    name: "",
    components: {
        Spinner,
        // ProgressBar,
        AlertDialog
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaigns: [],
            isCampaignsLoaded: false,
            showAlert: false,
            alert: {
                title: '',
                message: ''
            },
            statusNames: {
                0: "快來搶獎勵",
                1: "已領取獎勵",
                2: "獎勵已搶完",
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    computed: {
        ...mapState(["user", "userInfo"])
    },
    mounted() {
        this.getCampaigns();
    },
    methods: {
        isOverToday(campaign) {
            const today = moment();
            const endTime = moment(campaign.campaignEndTime * 1000);
            console.log('endTime', endTime.format('YYYY-MM-DD HH:mm:ss'));
            return endTime.isBefore(today);
        },
        onCloseAlertDialog() {
            this.showAlert = false;
            this.alert = {
                title: '',
                message: ''
            }
            this.getCampaigns();
        },
        parseContent(content) {
            if (!content) return ''
            let result = content.replace(/<\/?[^>]+(>|$)/g, "");
            result = result.replace(/&[^;\s]+;/g, "");
            result = result.trim();
            return result;
        },
        getStatusId(campaign) {
            return (campaign.hasCompleted && campaign.campaignPrototype !== "referral-referrer") ? 1 : (campaign.reward_remain > 0 ? 0 : 2)
        },
        getStatusName(campaign) {
            const idx = this.getStatusId(campaign);
            return this.statusNames[idx] || "Unknown";
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal&prototype=minigame&return=list`,
                methods: "GET"
            };
            return this.$http(config);
        },
        showStatusBtn(campaign) {
            return campaign.campaignPrototype !== "minigame-regular";
        },
        getCampaigns() {
            this.getCampaignAPI()
                .then((res) => {
                    this.campaigns = res.data.campaigns;
                    this.campaigns = this.campaigns.filter(c => {
                        if (c.campaignStatus !== 'approved') {
                            return false
                        }

                        if (!['minigame', 'minigame-regular'].includes(c.campaignPrototype)) {
                            return false
                        }

                        if (this.isOverToday(c)) {
                            return false
                        }

                        return true
                    });//NOTE: 只顯示visibility = 'public'的任務
                    this.sortCampaigns();
                    console.log(this.campaigns);
                })
                .finally(()=>{
                    this.isCampaignsLoaded = true;
                });
        },
        sortCampaigns() {
            let incompleteCampaigns = this.campaigns.filter(c => c.completeness == 'incomplete');
            let notParticipatingCampaigns = this.campaigns.filter(c => c.completeness == 'not-participating');
            let completedCampaigns = this.campaigns.filter(c => c.completeness == 'completed');

            function sortByTsDesc(b, a) {
                return a.campaignStartTime - b.campaignStartTime;
            }
            incompleteCampaigns.sort(sortByTsDesc);
            notParticipatingCampaigns.sort(sortByTsDesc);
            completedCampaigns.sort(sortByTsDesc);
            this.campaigns = incompleteCampaigns.concat(notParticipatingCampaigns).concat(completedCampaigns);
        },
        calMissions(campaign) {
            if(campaign && campaign.campaignPrototype == "stamp-collection" && campaign.missions && campaign.missions.length == 1) {
                let settings = campaign.missions[0].settings;
                if(settings && settings.milestones && settings.milestones.length > 0) {
                    let max = Math.max(...settings.milestones.map(milestone => milestone.completionCount));
                    return max;
                } else {
                    return 0;
                }
            } else {
                if (
                    campaign &&
                    campaign.missions &&
                    campaign.missions.length >= 0
                ) {
                    return campaign.missions.length;
                }
            }
            return 0;
        },
        calCompletedMissions(campaign) {
            if(campaign && campaign.campaignPrototype == "stamp-collection" && campaign.missions && campaign.missions.length == 1) {
                let participation = campaign.missions[0].participation;
                if(participation && participation.length > 0) {
                    return participation.filter(p => p.status == "completed").length;
                } else {
                    return 0;
                }
            } else {
                if (
                    campaign &&
                    campaign.missions &&
                    campaign.missions.length > 0 &&
                    campaign.missions.some((m) => m.participation)
                ) {
                    return campaign.missions.filter((m) => {
                        if (
                            m.participation &&
                            m.participation.length > 0 &&
                            m.participation[0].status == "completed"
                        ) {
                            return true;
                        }
                        return false;
                    }).length;
                }
            }
            return 0;
        },
        calNumerator(campaign) {
            let numerator = this.calCompletedMissions(campaign);
            let denominator = this.calMissions(campaign);
            return numerator > denominator ? denominator : numerator;
        },
        calProgress(campaign) {
            let numerator = this.calNumerator(campaign);
            let denominator = this.calMissions(campaign);
            return (numerator / denominator) * 100;
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        checkCampaignCompleted(campaign) {
            return campaign.completeness == 'completed';
        },
        processBarDisplay(campaign) {
            return campaign.campaignPrototype == "stamp-collection";
        },
        toCampaignDetail(campaign) {
            if (this.isOverToday(campaign)) {
                this.alert = {
                    title: '提醒',
                    message: '任務已下架'
                }
                this.showAlert = true
                return;
            }

            this.$store.commit("updateCampaign", campaign);
            this.$router.push(`/campaign/${campaign.campaignId}/detail`);
        },
        checkRewardCompleted(campaign) {
            return (campaign.reward_remain === 0);
        },
        showRewordInsufficient(campaign) {
            const {
                reward_remain,
                reward_total,
                hasCompleted,
                campaignPrototype,
            } = campaign;
            
            const isInsufficient =
                reward_remain > 0 &&
                reward_remain <= reward_total * 0.05;

            if (
                campaignPrototype === 'referral' ||
                campaignPrototype === 'stamp-collection'
            ) {
                return isInsufficient;
            } else {
                return !hasCompleted && isInsufficient;
            }
        },
        showCampaignCompleted(campaign) {
            return (
                this.checkCampaignCompleted(campaign) &&
                campaign.campaignPrototype !== 'referral' &&
                campaign.campaignPrototype !== 'stamp-collection'
            );
        },
    }
};
</script>
<style scoped lang="scss">
.flexV.width.margin.mt-2.news {
    margin-top: 20px!important;
}
.mini-game-page {
    .cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }
    .news-card {
        width: 100% !important;
        margin: 0 !important;

        .uide-mission-card {
            margin: 0 !important;
        }
    }

    .listEmpty {
        padding-top: 2rem;
    }
}
</style>