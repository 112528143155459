<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- 回頂端 -->
    <a id="toTop" class="toTop icon iconButton" @click.prevent="toTop()">
      <font-awesome-icon icon="fa-solid fa-chevron-up"></font-awesome-icon>
    </a>
    <!-- Header -->
    <section class="header index">
      <a class="left" @click.prevent="$router.push('/record')">
        <img class="logo" src="@/assets/img/favicon.svg" />
        <span class="primary">{{ displayUserPointBalance }}</span>
      </a>
      <img src="@/assets/img/title.svg" />
      <div class="right">
        <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
        <a class="icon iconButton" @click.prevent="$router.push('/barcode')">
          <!-- <font-awesome-icon icon="fa-solid fa-barcode" size="xl"></font-awesome-icon> -->
          <img src="@/assets/img/barcode.svg" />
        </a>
        <NotificationBell
          v-if="triggerCountUnread"
          @count-unread="countUnread = $event"
        />
      </div>
    </section>
    <!-- Main -->
    <section ref="main" class="main index">
      <!-- 功能 -->
      <div class="function flexH width padding hide">
        <a class="flexV center width" @click.prevent="$router.push('/record')">
          <img src="@/assets/icon/record.svg" />
          <span class="fM">{{ $t("交易紀錄") }}</span>
          <!-- <span class="fS"><img src="@/assets/icon/point.svg">{{ displayUserPointBalance }}</span> -->
        </a>
        <a class="flexV center width" @click.prevent="$router.push('/tickets')">
          <img src="@/assets/icon/ticket.svg" />
          <span class="fM">{{ $t("我的票券") }}</span>
          <!-- <span class="fS">{{ numberOfTickets }} {{ $t('張') }}</span> -->
        </a>
        <a
          class="flexV center width"
          @click.prevent="$router.push('/stamp-cards')"
        >
          <img src="@/assets/icon/stamp.svg" />
          <span class="fM">{{ $t("我的集章") }}</span>
          <!-- <span class="fS">{{ numberOfStampCards }} {{ $t('張') }}</span> -->
        </a>
      </div>
      <!-- Banner -->
      <div
        class="banners"
        id="banners"
        ref="banners"
        @touchstart.stop="touchStart"
        @touchmove.stop="touchMove"
      >
        <div
          class="banner"
          v-for="(banner, index) in banners"
          :key="'banner' + index"
        >
          <a
            @click.prevent="
              $router.push('/B2-detail/' + banner.id + '?type=banner')
            "
            ><img :src="banner.imageUrl"
          /></a>
        </div>

        <a
          v-if="banners && banners.length"
          class="prev"
          @click.prevent="plusSlides(-1, 0)"
          >&#10094;</a
        >
        <a
          v-if="banners && banners.length"
          class="next"
          @click.prevent="plusSlides(1, 0)"
          >&#10095;</a
        >
        <div class="dots" ref="dots">
          <span
            class="dot"
            v-for="(banner, index) in banners"
            :key="'dot' + index"
          ></span>
        </div>
      </div>
      <RouterLink class="tkMall" to="/service/questions">
        <img
          v-if="qnaBadge"
          class="cloud-badge"
          src="@/assets/img/cloud.svg"
          alt=""
        />
        <img src="@/assets/img/big-maga.svg" />
      </RouterLink>
      <!-- 任務 -->
      <div class="mission flexH width">
        <a class="title flexH middle width" @click.prevent="popupGuestMode">
          <div class="mission-tag-container">
            <img class="icon" src="@/assets/icon/coin.svg" />
            <span class="third" style="margin-left: 8px"
              >獎勵有限 送完為止</span
            >
          </div>
        </a>
        <a
          class="flexV center width button-item"
          @click.prevent="$router.push('/B1')"
        >
          <img
            v-if="showQuestionnaireBadge"
            class="cloud-badge"
            src="@/assets/img/cloud.svg"
            alt=""
          />
          <div class="logo-block">
            <img src="@/assets/icon/service-b.svg" />
          </div>
          <span class="fM">問卷任務</span>
          <!-- <div class="hot">
                <span>HOT</span>
            </div> -->
        </a>
        <a
          class="flexV center width button-item"
          @click.prevent="$router.push('/campaign')"
        >
          <img
            v-if="showxianminrenwu"
            class="cloud-badge"
            src="@/assets/img/cloud.svg"
            alt=""
          />
          <div class="logo-block">
            <img src="@/assets/icon/mission-c.svg" />
          </div>
          <span class="fM">專屬任務</span>
          <!-- <div class="hot">
                <span>HOT</span>
            </div> -->
        </a>
        <!-- 先拿掉變暗 -->
        <!-- <a
          class="flexV center width button-item disabled"
          
          @click.prevent="toVideo"
        > -->
        <a
          class="flexV center width button-item"
          @click.prevent="toVideo"
        >
          <img
            v-if="showVideoBadge"
            class="cloud-badge"
            src="@/assets/img/cloud.svg"
            alt=""
          />
          <div class="logo-block">
            <img v-if="videoBtnDisabled" src="@/assets/icon/video.svg" />
            <img v-if="!videoBtnDisabled" src="@/assets/icon/video-c.svg" />
          </div>
          <span
            class="fM"
            :class="{
              disabled: videoBtnDisabled,
            }"
            >{{ $t("看影片") }}</span
          >
          <!-- <div
            class="mission-lable"
            v-if="isCampaignLoaded && numberOfVideos > 0"
          >
            <img class="icon" src="@/assets/icon/coin.svg" />
          </div> -->
        </a>
        <!-- 先拿掉變暗 -->
        <!-- <a
          class="flexV center width button-item disabled"
          @click.prevent="toGame"
        > -->
        <a
          class="flexV center width button-item"
          @click.prevent="toGame"
        >
          <img
            v-if="showMiniGameBadge"
            class="cloud-badge"
            src="@/assets/img/cloud.svg"
            alt=""
          />
          <div class="logo-block">
            <img v-if="gameBtnDisabled" src="@/assets/icon/game.svg" />
            <img
              v-if="!gameBtnDisabled"
              src="@/assets/icon/game-new.svg"
              style="height: 32px"
            />
          </div>
          <span
            class="fM"
            :class="{
              disabled: gameBtnDisabled,
            }"
            >{{ $t("小遊戲") }}</span
          >
          <!-- <div
            class="mission-lable"
            v-if="isCampaignLoaded && numberOfMiniGames > 0"
          >
            <img class="icon" src="@/assets/icon/coin.svg" />
          </div> -->
        </a>
        <!-- <a
          class="flexV center width"
          :class="{ none: isCampaignLoaded && numberOfQuestions <= 0 }"
          @click.prevent="$router.push('/questions')"
        >
          <img src="@/assets/icon/question.svg" />
          <span class="fM">{{ $t('問答') }}</span>
          <div class="label" v-if="isCampaignLoaded && numberOfQuestions <= 0">
            <span class="fS primary single">暫無任務</span>
          </div>
        </a> -->
      </div>
    </section>
    <!-- Popup (無問答) -->
    <Popup
      :open="noQAPopup.toPopup"
      :title="noqa.title"
      :message="noQAPopup.message"
      :footer="noQAPopup.footer"
      @close="noQAPopupClose()"
    />
    <!-- Popup (獲得點數) -->
    <Popup
      :open="getPointPopup.toPopup"
      :title="getPointPopup.title"
      :message="getPointPopup.message"
      :footer="getPointPopup.footer"
      :buttonLeft="getPointPopup.buttonLeft"
      :buttonRight="getPointPopup.buttonRight"
      @close="() => {}"
      @btn-left="getPointPopupBtnLeft()"
      @btn-right="() => {}"
    />
    <!-- Popup (Code) -->
    <BarcodePopup
      v-if="barcodePopupOpen"
      @closePopup="barcodePopupOpen = false"
    />
    <!-- 首次加入popup -->
    <section
      v-if="introPopOpen"
      id="popIntro"
      class="pop intro"
      style="display: flex"
    >
      <div class="popBody" v-if="introImages.length">
        <Slider
          v-model="introSliderValue"
          :speed="500"
          :interval="5000"
          :autoplay="false"
          :control-btn="introImages.length > 1"
          :indicators="introImages.length > 1 ? 'center' : false"
          width="100%"
          height="100%"
        >
          <SliderItem
            v-for="(image, index) in introImages"
            :key="'intro_' + index"
          >
            <img :src="image.imageUrl" @load="introImgLoadHandler" />
            <!-- <img src="@/assets/img/intro.png"> -->
          </SliderItem>
          <!-- <div slot="loading"></div> -->
        </Slider>
        <!-- ********** v最後一頁才出現v ********** -->
        <button v-if="showStartBtn" @click.prevent="startBtnClickHandler">
          <span>開始</span>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import { Slider, SliderItem } from "vue-easy-slider";
import img1 from "@/assets/homepage/01. 樂天桃園棒球場.jpg";
import img2 from "@/assets/homepage/02. 稅創藝.jpg";
import NotificationBell from "@/components/NotificationBell.vue";
import Popup from "@/components/Popup";
import BarcodePopup from "../components/BarcodePopup.vue";

export default {
  name: "Home",
  components: {
    Popup,
    NotificationBell,
    BarcodePopup,
    Slider,
    SliderItem,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
      slideIndex: 1,
      countUnread: 0,
      notifications: [],
      documents: [],
      firstNotification: {},
      news: [
        {
          title: "樂天桃園棒球場「桃園日」活動，招待本市民眾免費進場觀賽",
          description:
            "桃園市政府於2022中華職棒上半年賽事推出13場次「桃園日」活動，招待各行政區市民免費觀賽。",
          date: "2022/06/22",
          department: "桃園市政府體育局",
          imageUrl: img1,
        },
        {
          title: "「稅創藝」租稅短片火熱徵件， 總奬金12萬元等你哦!",
          description:
            "桃園市政府地方稅務局即日起開始徵件，個人或團體皆可報名參加，只要善用巧思製作1至3分鐘的創意短片，就有機會獲得新臺幣3萬5千元的首奬獎金。",
          date: "",
          department: "桃園市政府稅務局",
          imageUrl: img2,
        },
      ],
      noqa: {
        title: "已完成所有問答",
      },
      getPointPopup: {
        toPopup: false,
        title: "",
        message: "",
        footer: true,
        buttonLeft: "前往點數頁",
        buttonRight: "關閉",
        reset: function () {
          this.toPopup = false;
          this.tilte = "";
          this.message = "";
          this.footer = true;
          this.buttonLeft = "前往點數頁";
          this.buttonRight = "關閉";
        },
      },
      noQAPopup: {
        toPopup: false,
        message: "明天再回來看看吧！",
        footer: false,
        reset: function () {
          this.toPopup = false;
          this.message = "";
          this.footer = false;
        },
      },
      isNotificationBar: false,
      notificationBarContent: {
        title: "",
        message: "",
        time: "",
        points: "",
        reset: function () {
          this.title = "";
          this.message = "";
          this.time = "";
          this.points = "";
        },
      },
      banners: [],
      campaigns: [],
      categoryType: "news", // "campaigns",
      autoPlay: true, // 自動輪播
      toggleTimer: true, // pause auto play
      delay: 3000, // 輪播時間
      intervalId: null,
      xDown: null,
      yDown: null,
      stampCards: [],
      tickets: [],
      isCampaignLoaded: false,
      barcodePopupOpen: false,
      triggerCountUnread: false,
      introImages: [],
      introSliderValue: 0,
      showStartBtn: false,
      ecUrl: null,
      introPopOpen: false,
      videoBtnDisabled: false,
      gameBtnDisabled: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next((vm) => {
      let that = vm;
      vm.getCampaigns()
      if (that.user && that.user.userId) {
        //
      } else {
        next("/error");
      }
    });
  },
  created() {
    // this.updateUserPoints();
    this.getUserPreferences()
    // .then((res) => {
    //   if (!res.data.isIntroductionRead) this.getIntroImages();
    // });
    // this.getArticles();
  },
  activated() {
    this.updateUserPoints();
    this.triggerCountUnread = true;
  },
  deactivated() {
    this.noQAPopupClose();
    // this.getPointPopupClose();
    this.barcodePopupOpen = false;
    this.triggerCountUnread = false;
    // this.closeIntro();
  },
  computed: {
    ...mapState([
      "user",
      "userInfo",
      "currentPath",
      "isFinishQuestions",
      "isQANotExist",
    ]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
      articles: "article/getArticles",
    }),
    qnaBadge() {
      return this.campaigns.some(c =>
        c.campaignPrototype === "qna" && c.unread
      );
    },
    showQuestionnaireBadge() {
      return this.campaigns.some(c =>
        ["questionnaire"].includes(c.campaignPrototype) && c.unread
      );
    },
    showxianminrenwu() {
      return this.campaigns.some(c =>
        ['referral-referrer', 'check-in', 'daily-sign'].includes(c.campaignPrototype) && c.unread
      );
    },
    showVideoBadge() {
      return this.campaigns.some(c =>
        c.campaignPrototype === "video" && c.unread
      );
    },
    showMiniGameBadge() {
      return this.campaigns.some(c =>
        c.campaignPrototype === "minigame" && c.unread
      )
    },
    lastestEndOfPoint() {
      if (
        this.userPoints &&
        this.userPoints.pointPeriods &&
        this.userPoints.pointPeriods.length > 0
      ) {
        let sorted = [...this.userPoints.pointPeriods];
        sorted.sort(function (a, b) {
          return a.endTs - b.endTs;
        });
        const latestEndOfPoint = sorted.find((p) => p.endTs >= moment().unix());
        return latestEndOfPoint;
      }
      return null;
    },
    displayDate() {
      return this.lastestEndOfPoint
        ? this.tsToData(this.lastestEndOfPoint.endTs)
        : "";
    },
    displayUserPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    numberOfCampaigns() {
      if (this.campaigns && this.campaigns.length) {
        const matched = this.campaigns.filter(
          (c) => c.campaignVisibility === "public"
        );
        return matched.length;
      } else {
        return 0;
      }
    },
    numberOfMiniGames() {
      if (this.campaigns && this.campaigns.length) {
        const matched = this.campaigns.filter(
          (c) => c.campaignPrototype === "minigame"
        );
        return matched.length;
      } else {
        return 0;
      }
    },
    numberOfVideos() {
      if (this.campaigns && this.campaigns.length) {
        const matched = this.campaigns.filter(
          (c) => c.campaignPrototype === "video"
        );

        return matched.length;
      } else {
        return 0;
      }
    },
    numberOfQuestions() {
      if (this.campaigns && this.campaigns.length) {
        const matched = this.campaigns.filter(
          (c) => c.campaignPrototype === "qna"
        );
        return matched.length;
      } else {
        return 0;
      }
    },
    miniGameType() {
      if (!this.numberOfMiniGames) return "";
      return this.campaigns.find((c) => c.campaignPrototype === "minigame")
        .missions[0].missionType;
    },
    enableStamp() {
      return process.env.VUE_APP_ENABLE_STAMP === "true";
    },
  },
  watch: {
    introSliderValue(val) {
      if (val == 0) {
        this.hideSliderPrevArrow();
      } else {
        this.showSliderPrevArrow();
      }
      if (this.showStartBtn) return;
      if (val == this.introImages.length - 1) {
        this.showStartBtn = true;
      }
    },
  },
  mounted() {
    if (this.isFinishQuestions) {
      this.noqa.title = "已完成所有問答";
      this.popNoQA();
    }
    if (this.isQANotExist) {
      this.noqa.title = "今日沒有問答題目唷";
      this.popNoQA();
    }
    this.showToTopBtn();
    this.getBanners().then(() => {
      this.showCarousel();
    });
    this.getCampaigns();
    this.getEcUrl();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    toGame() {
      if (this.gameBtnDisabled) {
        return;
      }
      this.$router.push("/mini-games");
      // const miniGame = this.campaigns.find(
      //   (c) => c.campaignPrototype === "minigame"
      // );

      // console.log('miniGame', miniGame);
      // this.$store.commit("updateCampaign", miniGame);
      // this.$router.push(`/campaign/${miniGame.campaignId}/detail`);
    },
    toVideo() {
      if (this.videoBtnDisabled) {
        return;
      }
      this.$router.push("/videos");
    },
    dummyArticle() {
      let result = [
        {
          id: 1,
          title: "測試最新消息",
          htmlContent:
            '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
          imageUrl:
            "https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png",
          status: "approved",
          startTs: 1651593600,
          endTs: 1654358399,
          departmentName: "規劃發展科",
          categoryId: 1,
          categoryName: "其它",
        },
        {
          id: 1,
          title: "測試最新消息",
          htmlContent:
            '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
          imageUrl:
            "https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png",
          status: "approved",
          startTs: 1651593600,
          endTs: 1654358399,
          departmentName: "規劃發展科",
          categoryId: 1,
          categoryName: "其它",
        },
        {
          id: 1,
          title: "測試最新消息",
          htmlContent:
            '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
          imageUrl:
            "https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png",
          status: "approved",
          startTs: 1651593600,
          endTs: 1654358399,
          departmentName: "規劃發展科",
          categoryId: 1,
          categoryName: "其它",
        },
      ];
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callArticleAPI() {
      var config = {
        method: "get",
        url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/articles?category_type=${this.categoryType}`,
        // url: 'https://shopware.yunlin.citycoins.cc/store-api/core/articles/v1/merchants/yunlin/articles',
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getArticles() {
      this.callArticleAPI()
        // this.dummyArticle()
        .then((res) => {
          console.log("res: ", res);
          this.$store.commit(
            "article/setArticles",
            JSON.parse(JSON.stringify(res.data.articles))
          );
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    },
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    isOverToday(campaign) {
      const today = moment();
      const endTime = moment(campaign.campaignEndTime * 1000);
      return endTime.isBefore(today);
    },
    getCampaigns() {
      return (
        this.getCampaignAPI()
          // this.campaignDummy()
          .then((res) => {
            this.campaigns = res.data.campaigns.filter((c) => {
              if (c.campaignStatus !== 'approved') {
                return false
              }

              if (this.isOverToday(c)) {
                return false
              }

              return true
            });
            return res;
          })
          .finally(() => {
            this.isCampaignLoaded = true;
          })
      );
    },
    getCampaignAPI() {
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal&return=list`,
        method: "GET",
      };
      return this.$http(config);
    },
    tsToData(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD");
    },
    getUserPoints() {
      var config = {
        method: "get",
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showSlides(n) {
      var i;
      var el = this.$refs["banners"];
      var slides = el.querySelectorAll("div.banner");
      var dotEl = this.$refs["dots"];
      var dots = dotEl.querySelectorAll("span.dot");
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "flex";
      dots[this.slideIndex - 1].className += " active";
      this.toggleTimer = true;
    },
    plusSlides(n) {
      this.toggleTimer = false;
      this.showSlides((this.slideIndex += n));
      if (this.autoPlay && this.intervalId) {
        clearInterval(this.intervalId);
        this.autoPlayCarousel();
      }
    },
    swipedetect(el, callback) {
      var touchsurface = el,
        swipedir,
        startX,
        startY,
        distX,
        distY,
        elapsedTime,
        startTime,
        threshold = 50, //required min distance traveled to be considered swipe
        restraint = 100, // maximum distance allowed at the same time in perpendicular direction
        allowedTime = 300, // maximum time allowed to travel that distance
        handleswipe =
          callback ||
          function (swipedir) {
            console.log("swipedir:" + swipedir);
          };
      touchsurface.addEventListener(
        "touchstart",
        function (e) {
          var touchobj = e.changedTouches[0];
          swipedir = "none";
          dist = 0;
          startX = touchobj.pageX;
          startY = touchobj.pageY;
          startTime = new Date().getTime(); // record time when finger first makes contact with surface
          e.preventDefault();
        },
        false
      );
      touchsurface.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault(); // prevent scrolling when inside DIV
        },
        false
      );
      touchsurface.addEventListener(
        "touchend",
        function (e) {
          var touchobj = e.changedTouches[0];
          distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
          distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
          elapsedTime = new Date().getTime() - startTime; // get time elapsed
          if (elapsedTime <= allowedTime) {
            // first condition for awipe met
            if (Math.abs(distX) < threshold) {
              window.location = el.children.item(slideIndex - 1).href;
              // console.log(el.children.item(slideIndex-1).href);
            } else if (
              Math.abs(distX) >= threshold &&
              Math.abs(distY) <= restraint
            ) {
              // 2nd condition for horizontal swipe met
              swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
            } else if (
              Math.abs(distY) >= threshold &&
              Math.abs(distX) <= restraint
            ) {
              // 2nd condition for vertical swipe met
              swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
            }
          }
          handleswipe(swipedir);
          e.preventDefault();
        },
        false
      );
    },
    showCarousel() {
      this.showSlides(1);
      if (this.autoPlay) {
        this.autoPlayCarousel();
      }
      // var el = this.$refs["banners"];
      // this.swipedetect(
      //     el,
      //     function (swipedir) {
      //         // swipedir contains either "none", "left", "right", "top", or "down"
      //         if (swipedir == "left") this.plusSlides(1);
      //         if (swipedir == "right") this.plusSlides(-1);
      //     }.bind(this)
      // );
    },
    // swiper event(for mobile)
    touchStart(e) {
      this.xDown = e.touches[0].clientX;
      this.yDown = e.touches[0].clientY;
    },
    touchMove(e) {
      const _this = this;
      if (!this.xDown || !this.yDown) {
        return;
      }

      const xUp = e.touches[0].clientX;
      const yUp = e.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        xDiff > 0 ? _this.plusSlides(1, 0) : _this.plusSlides(-1, 0);
      }
      this.xDown = null;
      this.yDown = null;
    },
    // 自動輪播
    autoPlayCarousel() {
      this.intervalId = setInterval(() => {
        if (this.toggleTimer) {
          this.showSlides((this.slideIndex += 1));
        }
      }, this.delay);
    },
    readPopupMsg() {
      // let collection = db.collection(
      //     `notifications/${this.merchantId}/topics/${this.user.user.firestoreChannelId}/messages`
      // );
      // if (this.firstNotification.docId) {
      //     let docId = this.firstNotification.docId;
      //     let docRef = collection.doc(docId);
      //     let batch = db.batch();
      //     batch.update(docRef, { getNotify: true });
      //     // batch.update(docRef, {unread: false});
      //     batch.commit().then(() => {
      //         console.log("update unread!");
      //     });
      //     this.firstNotification.docId = null;
      // }
      //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
      let config = {
        url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
        method: "POST",
        data: {
          topicId: this.user.user.firestoreChannelId,
          member_id: this.userInfo.memberId,
          uuid: this.user.uuid,
          message_id: this.firstNotification.docId,
        },
      };
      return this.$http(config);
    },
    popClose(e) {
      let target = e.target;
      target.closest(".popup").style.display = "none";
      document.body.style.overflow = "scroll";
      this.$store.commit("updateIsFinishQuestions", false);
      this.$store.commit("updateIsQANotExist", false);
    },
    popNoQABgClose() {
      let popNoOAModal = document.getElementById("popNoQA");
      window.onclick = (event) => {
        if (event.target == popNoOAModal) {
          popNoOAModal.style.display = "none";
          document.body.style.overflow = "scroll";
          this.$store.commit("updateIsQANotExist", false);
        }
      };
    },
    popNoQA() {
      this.noQAPopup.toPopup = true;
    },
    getPointHandler(event) {
      console.log("get points: ", event);
      this.updateUserPoints();
    },
    updateUserPoints() {
      this.getUserPoints()
        .then((res) => {
          console.log("update user points!");
          this.$store.commit("updateUserPoints", res);
        })
        .catch((err) => {
          console.log("err: ", err);
          this.router.push("/error");
        });
    },
    getPointPopupClose() {
      this.readPopupMsg();
      this.getPointPopup.toPopup = false;
    },
    getPointPopupBtnLeft() {
      this.readPopupMsg();
      this.getPointPopup.toPopup = false;
      this.$router.push("/record");
    },
    noQAPopupClose() {
      this.noQAPopup.toPopup = false;
    },
    showToTopBtn() {
      let target = this.$refs["main"];
      target.onscroll = function () {
        var top = target.scrollTop == 0;
        document.getElementById("toTop").style.display = top ? "none" : "flex";
      };
    },
    toTop() {
      let target = this.$refs["main"];
      target.scrollTo({ top: 0, behavior: "smooth" });
    },
    dummyBanner() {
      let result = [
        {
          imageUrl:
            "https://storage.googleapis.com/appointed_store_images/tycard2022/home/29eb194b-a1eb-44c6-9f92-4df57e6287dc.png",
          description: "卡好女王節",
          externalUrl: "https://taoyuan1000.tycg.gov.tw",
          sequence: 0,
        },
        {
          imageUrl:
            "https://storage.googleapis.com/appointed_store_images/tycard2022/home/29eb194b-a1eb-44c6-9f92-4df57e6287dc.png",
          description: "卡好女王節",
          externalUrl: "https://taoyuan1000.tycg.gov.tw",
          sequence: 0,
        },
        {
          imageUrl:
            "https://storage.googleapis.com/appointed_store_images/tycard2022/home/29eb194b-a1eb-44c6-9f92-4df57e6287dc.png",
          description: "卡好女王節",
          externalUrl: "https://taoyuan1000.tycg.gov.tw",
          sequence: 0,
        },
      ];
      return new Promise((resolve) => {
        resolve({ data: result });
      });
    },
    callBannerAPI() {
      let config = {
        url: `${this.apiHost}/banners/v1/merchants/yunlin/banners`,
        method: "GET",
      };
      return this.$http(config);
    },
    getBanners() {
      return (
        this.callBannerAPI()
          // return this.dummyBanner()
          .then((callBannerAPIRes) => {
            console.log("callBannerAPIRes: ", callBannerAPIRes);
            this.banners = callBannerAPIRes.data.banners;
            return;
          })
          .catch((callBannerAPIErr) => {
            console.log("callBannerAPIErr: ", callBannerAPIErr);
          })
      );
    },
    toArticle(articleId) {
      this.$router.push({ path: `/articles/${articleId}` });
    },
    openExternalUrl(externalUrl) {
      if (externalUrl) {
        window.open(externalUrl, "_blank");
      }
    },
    changeCetegoryOfArticle(type) {
      console.log("changeCetegoryOfArticle: ", type);
      this.categoryType = type;
      this.getArticles();
    },
    callGetStampCardsAPI() {
      let config = {
        // url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/collector/${this.user.userId}/stamp-cards`
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards?uuid=${this.user.userId}`,
      };
      console.log("config: ", config);
      return this.$http(config);
    },
    getStampCards() {
      this.callGetStampCardsAPI()
        .then((res) => {
          this.stampCards = res.data;
          console.log("callGetStampCardsAPI: ", res.data);
        })
        .catch((err) => console.log(err));
    },
    callGetTicketsAPI() {
      let config = {
        url: `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets`,
      };
      return this.$http(config);
    },
    getTickets() {
      this.callGetTicketsAPI()
        .then((res) => {
          this.tickets = res.data;
        })
        .catch((err) => console.log(err));
    },
    getUserPreferences() {
      var config = {
        method: "get",
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/preferences`,
        headers: {},
      };
      return this.$http(config);
    },
    updateUserPreferences() {
      let data = JSON.stringify({
        isIntroductionRead: 1,
      });
      let config = {
        method: "put",
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/preferences`,
        data: data,
      };
      return this.$http(config);
    },
    getIntroImages() {
      let config = {
        url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/images?type=newcomer`,
      };
      return this.$http(config)
        .then((res) => {
          this.introImages = res.data;
          if (this.introImages.length <= 1) {
            this.showStartBtn = true;
          }
        })
        .catch((err) => console.log(err));
    },
    introImgLoadHandler() {
      if (this.introPopOpen) return;
      this.hideSliderPrevArrow();
      setTimeout(() => {
        // this.introPopOpen = true;
      }, 200);
    },
    startBtnClickHandler() {
      this.introPopOpen = false;
      this.introImages = [];
      this.updateUserPreferences();
    },
    showSliderPrevArrow() {
      document.querySelector(".slider-btn-left").style.display = "block";
    },
    hideSliderPrevArrow() {
      const prevArrow = document.querySelector(".slider-btn-left");
      if (prevArrow) prevArrow.style.display = "none";
    },
    callGetEcUrlAPI() {
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/app-auth/ec`,
        method: "get",
      };
      return this.$http(config);
    },
    getEcUrl() {
      this.callGetEcUrlAPI().then((res) => {
        this.ecUrl = res.data.authUrl;
      });
    },
    goToMiniGame() {
      this.miniGameType === "slot-machine"
        ? (window.location.href = this.appHost + "slot-machine/")
        : this.$router.push("/turntable");
    },
  },
};
</script>

<style scoped src="@/assets/css/payment.css"></style>
<style scoped>
.disabled {
  color: var(--cOther2) !important;
}
</style>
<style lang="scss">
#popIntro .slider-btn {
  background: unset !important;
  height: unset !important;
  width: unset !important;
  top: unset !important;
  bottom: 25px !important;
}

#popIntro .slider-btn-left {
  left: 2rem !important;
}

#popIntro .slider-btn-right {
  right: 2rem !important;
}

#popIntro .slider-btn .slider-icon {
  border-left: 2px solid #ef6079 !important;
  border-bottom: 2px solid #ef6079 !important;
  width: 10px !important;
  height: 10px !important;
}

#popIntro .slider-indicators {
  bottom: 25px !important;
}

#popIntro .slider-indicator-icon {
  width: 6px !important;
  height: 6px !important;
  margin: 5px !important;
}

#popIntro .slider-indicator-active {
  background-color: #ef6079 !important;
}
.cloud-badge {
  position: absolute;
  width: 18px !important;
  height: 18px !important;
  top: -4px;
  right: 12px;
}
.button-item {
  display: flex;
  flex-direction: column;
}
.logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.tkMall {
  position: relative;

  .cloud-badge {
    right: 0;
    width: 32px !important;
    height: 32px !important;
  }
}
</style>
